import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import store from "./store";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/hp-users",
        name: "hp-users",
        component: () => import("./pages/master/HpUsers.vue"),
      },
      {
        path: "/web-users",
        name: "web-users",
        component: () => import("./pages/master/WebUsers.vue"),
      },
      {
        path: "/delivery-man",
        name: "delivery-man",
        component: () => import("./pages/master/DeliveryMan.vue"),
      },
      {
        path: "/distributor",
        name: "distributor",
        component: () => import("./pages/master/Distributor.vue"),
      },
      {
        path: "/delivery-routes",
        name: "delivery-routes",
        component: () => import("./pages/master/DeliveryRoutes.vue"),
      },
      // {
      //   path: "/visit",
      //   name: "visit",
      //   component: () => import("./pages/report/Visit.vue"),
      // },
      // {
      //   path: "/start-end",
      //   name: "start-end",
      //   component: () => import("./pages/report/StartEnd.vue"),
      // },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("authToken")) {
    next({ name: "login" });
  } else if (to.name === "login" && localStorage.getItem("authToken")) {
    next({ name: "dashboard" });
  } else {
    if (to.name == "access" || to.name == "error" || to.name == "NotFound") {
      next();
      return;
    }

    const pageName = to.name;

    if (localStorage.getItem("authToken") && to.name !== "access") {
      store.dispatch("auth/checkPermission", pageName);
      setTimeout(() => {
        if (localStorage.getItem("isPermission") == "false") {
          next({ name: "access" });
        } else {
          next();
        }
      }, 250);
    } else {
      next();
    }
  }
});
export default router;
